import { formatCurrency } from '@angular/common'
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnInit,
	Output,
} from '@angular/core'
import { GiftVoucherRedemeed } from 'src/app/interfaces/gift.interface'
import { STATUS_ORDER } from 'src/app/interfaces/status.interface'

@Component({
	selector: 'app-gift-card',
	templateUrl: './gift-card.component.html',
	styleUrls: ['./gift-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GiftCardComponent implements OnInit {
	@Input() gift!: GiftVoucherRedemeed
	@Input() isHistory!: boolean
	@Output() buttonClicked = new EventEmitter<STATUS_ORDER>()
	template!: STATUS_ORDER | undefined

	text: Record<STATUS_ORDER, any> = {
		CHK_CANCEL: 'Recusado',
		VALIDATED: 'Validado',
	}
	ngOnInit(): void {
		this.setTemplate()
	}

	private setTemplate() {
		if (this.gift.status === 'Redeemed') {
			this.template = 'VALIDATED'
		}
	}

	@HostBinding('style')
	get addProps() {
		if (!this.isHistory && !this.template) {
			return
		}

		if (this.template) {
			const config: Record<STATUS_ORDER, any> = {
				CHK_CANCEL: { '--color': 'var(--red)', '--bg-color': 'var(--pink6)', '--opacity': '20%' },
				VALIDATED: { '--color': 'var(--green)', '--bg-color': 'var(--greenE)', '--opacity': '20%' },
			}
			return config[this.template]
		}
	}

	get benefit() {
		return `${this.gift.gift.name} - ${this.gift.gift.description}`.replace(
			'{{value}}',
			formatCurrency(this.gift.value, 'pt-BR', 'R$', 'BRL', '1.2-2')
		)
	}

	clickButton(type: STATUS_ORDER) {
		this.buttonClicked.emit(type)
	}
}
