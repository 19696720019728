import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { VenuesManagementService } from '../venues-management.service'
import { VenuesConfigApi } from 'src/app/services/venues-config/venues-config.service'
import { UpdatePaymentDto } from 'src/app/services/venues-config/venues-config.interfaces'
import { ModalService } from 'src/app/components/modalChannels/modal.service'
import { Subject, takeUntil } from 'rxjs'
import { VenuesManagementCacheService } from '../venue-management/venue-management-cache.service'
import { Venue } from '../venues.interface'
import { PartnersId } from 'src/shared/util'

@Component({
	templateUrl: './edit-payment.page.html',
	styleUrls: ['./edit-payment.page.scss'],
})
export class EditPaymentsPage implements OnInit, OnDestroy {
	form!: FormGroup
	venueId: string | null = this.venuesManagementService.venueId
	partnerId: string | null = this.venuesManagementService.partnerId
	errorMessage: string | null = null

	retentionTagmePercentSuggestion: number = 0
	retentionPartnerPercentSuggestion: number = 0
	benefitValue: number = 0

	modal = {
		error: 'modalErrorId',
		success: 'modalSuccessId',
	}

	private destroy$ = new Subject<void>()

	constructor(
		private fb: FormBuilder,
		private venuesManagementService: VenuesManagementService,
		private venuesCache: VenuesManagementCacheService,
		private venuesConfigApi: VenuesConfigApi,
		private modalService: ModalService
	) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			transactionValue: [],
			retentionPartnerPercent: [],
			retentionTagmePercent: [],
			acquirerPercent: [],
			discountPercent: [],
			gratuityPercent: [],
		})
		this.venuesManagementService.ensurePrimaryKey()
		this.venuesManagementService.venueConfig$.pipe(takeUntil(this.destroy$)).subscribe({
			next: venueConfig => {
				if (!venueConfig?.payments) return
				const { payments } = venueConfig
				const {
					transactionValue,
					retentionPartnerPercent,
					retentionTagmePercent,
					acquirerPercent,
					discountPercent,
					gratuityPercent,
				} = payments
				this.form.patchValue({
					transactionValue,
					retentionPartnerPercent,
					retentionTagmePercent,
					acquirerPercent,
					discountPercent,
					gratuityPercent,
				})
			},
			error: err => {
				this.errorMessage = err.error.message
				this.modalService.open(this.modal.error)
			},
		})

		if (this.partnerId === PartnersId.BB) {
			this.venuesCache
				.getVenueById({ _id: this.venueId } as Venue, this.partnerId as string)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: venue => {
						const points = venue.benefits.find(benefit => benefit.type === 'points')
						const exception = points.exceptions?.find(obj => obj.venueId === this.venueId)
						const benefitValue = Number(exception?.value || points.value)
						this.benefitValue = benefitValue
						// L(x) = 0.0378 * x
						this.retentionPartnerPercentSuggestion = Number(
							(benefitValue * (0.0378 * 100)).toFixed(2)
						)
						// T(x) = 1.22 + (x-1) * 0.22
						/* why?
							Pontos	Porcentagem Livelo (%)	Porcentagem Tagme (%)
							1	3.78	1.22
							2	7.56	1.44
							3	11.34	1.66
							4	15.12	1.88
							5	18.90	2.10
							A porcentagem de Tagme está aumentando linearmente em 0.22% a cada ponto adicional.
						*/
						this.retentionTagmePercentSuggestion = Number(
							(1.22 + (benefitValue - 1) * 0.22).toFixed(2)
						)
					},
				})
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
	}

	handleSubmit() {
		if (this.form.invalid) return
		const updatePayment = {
			venueId: this.venueId,
			partnerId: this.partnerId,
			transactionValue: this.form.value.transactionValue,
			retentionPartnerPercent: this.form.value.retentionPartnerPercent,
			retentionTagmePercent: this.form.value.retentionTagmePercent,
			// acquirerPercent: this.form.value.acquirerPercent,
			discountPercent: this.form.value.discountPercent,
			gratuityPercent: this.form.value.gratuityPercent,
		} as UpdatePaymentDto
		this.venuesConfigApi.updatePayment(updatePayment).subscribe({
			next: () => {
				this.modalService.open(this.modal.success)
			},
			error: err => {
				this.errorMessage = err.error.message
				this.modalService.open(this.modal.error)
			},
		})
	}
}
