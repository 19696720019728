import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment as env } from 'src/environments/environment' //ALTERAR VARIÁVEIS PARA PARCEIRO ITAÚ
import { Observable, delay, of } from 'rxjs'
import {
	ReportGiftItau,
	ReportGiftItauConverted,
	ReportItauRedemptionsConverted,
	ReportItauRedemptionsResponse,
} from './report.interface'
import { formatUtil } from 'src/app/services/format.util'
import { formatDateToDDMMYYYY } from 'src/app/services/date.util'

export type Option = {
	icon: string
	name: string
	slug: string
	preview?: boolean
	sendEmail?: boolean
}

export type Sector = {
	title: string
	options: Option[]
}
@Injectable({ providedIn: 'root' })
export class ReportsItauService {
	public sectors: Sector[] = [
		{
			title: 'Canais',
			options: [
				{
					icon: 'tagmeicon tagmeicon-presente',
					name: 'Reservas com vouchers',
					slug: 'reservas-itau',
				},

				// {
				// 	icon: 'tagmeicon tagmeicon-presente',
				// 	name: 'Reservas Itaú Shop (sem vouchers)',
				// 	slug: 'reservas-itau-shop',
				// },
			],
		},
		{
			title: 'Itaú Shop',
			options: [
				{
					icon: 'tagmeicon tagmeicon-presente',
					name: 'Reservas Itaú Shop (sem vouchers)',
					slug: 'reservas-itau-shop',
				},
			],
		},
		{
			title: 'Financeiro',
			options: [
				{
					icon: 'tagmeicon tagmeicon-voucher-mini',
					name: 'Emissão de gift',
					slug: 'emissao-gift',
				},
				{
					icon: 'tagmeicon tagmeicon-voucher-mini',
					name: 'Nota fiscal consolidado por restaurante (vouchers, resgate itau shop e gift)',
					slug: 'nota-fiscal-venue',
				},
			],
		},
	]

	public options = this.sectors.flatMap(sector => sector.options)

	constructor(private http: HttpClient) {}

	chooseReportToCall(
		slug: string,
		query: {
			startDate: string
			endDate: string
			partnerId?: string
			filterDate?: string
			venue?: string
		},
		format: 'csv' | 'json' = 'csv'
	): Observable<any> {
		const responseType = (format === 'csv' ? 'blob' : 'json') as any
		switch (slug) {
			case 'reservas-itau':
				return this.http.get(
					`${env.channelsAPI.baseURL}reports/menuPerson/benefits?startDate=${query.startDate}&endDate=${query.endDate}&format=${format}`,
					{ responseType }
				)
			case 'reservas-itau-shop':
				return this.http.get(
					`${env.channelsAPI.baseURL}reports/partner/menu-personnalite?startDate=${query.startDate}&endDate=${query.endDate}&format=${format}`,
					{ responseType }
				)

			case 'emissao-gift':
				return this.http.get(
					`${env.giftURL}/report/vouchers?startDate=${query.startDate}&expirationDate=${query.endDate}&venue=${query.venue}`,
					{ responseType }
				)

			case 'nota-fiscal-venue':
				return this.http.get(
					`${env.channelsAPI.baseURL}reports/menuPerson/financial?startDate=${query.startDate}&endDate=${query.endDate}&format=${format}&group=${query.filterDate}`,
					{ responseType }
				)
			default:
				console.warn('Nenhum slug encontrado')
				return of([])
		}
	}

	sendFinancialEmail(body: {
		monthYear: string
		previewDateLimit: string
		notaFiscalDateLimit: string
		venuesToSend: {
			venueId: string
			venueName: string
			qtdWine: number
			qtdMenu: number
			totalValue: string // R$ 100,00 (formatado)
		}[]
	}) {
		const sendgridTemplateId = 'd-4c0993c4ca194cedade8cc7f274858dd'
		return this.http.post(`${env.channelsAPI.baseURL}email/menuPerson/send/financial`, {
			...body,
			sendgridTemplateId,
		})
		return of({
			success: [
				{
					venueId: '12b',
					venueName: 'Bistro',
					message: 'E-mail enviado para: teste1@mail.com, teste2@email.com',
				},
				{
					venueId: '13b',
					venueName: 'Jappa',
					message: 'E-mail enviado para: teste1@mail.com, teste2@email.com',
				},
			],
			failures: [
				{
					venueId: '14b',
					venueName: 'Jappa',
					message: 'Não tem e-mail cadastrado. Sendgrid deu erro.',
					retry: true,
				},
				{
					venueId: '15b',
					venueName: 'Jappa 2',
					message: 'Não tem e-mail cadastrado. Sendgrid deu erro.',
				},
			],
		}).pipe(delay(2000))
	}

	reportsItauGiftAdapter(data: ReportGiftItau): ReportGiftItauConverted {
		return {
			Código: data.code,
			Nome: data.customerFullName,
			email: data.customerEmail,
			//Telefone: data.phone,
			'Valor do Gift': data.giftNominalValue ? formatUtil.formatCurrency(data.giftNominalValue) : '',
			'Nome do Gift': data.gift.name,
			//'Número do Lote': data.lotNumber,
			'Data de Emissão': formatDateToDDMMYYYY(new Date(data.createdAt)),
			'Data inicial': formatDateToDDMMYYYY(new Date(data.startDate)),
			'Data de Validade': formatDateToDDMMYYYY(new Date(data.expirationDate)),
			'Valor Bruto Recebido': data.tagmeGrossRevenueValue
				? formatUtil.formatCurrency(data.tagmeGrossRevenueValue)
				: '',
			'Valor Líquido Recebido': data.tagmeNetRevenueValue
				? formatUtil.formatCurrency(data.tagmeNetRevenueValue)
				: '',
			'Valor de Repasse': data.venueTransferValue
				? formatUtil.formatCurrency(data.venueTransferValue)
				: '',
		}
	}

	reportsItauRedemptionAdapter(data: ReportItauRedemptionsResponse): ReportItauRedemptionsConverted {
		return {
			'ID da Venue': data.venueId,
			Restaurante: data.venueName,
			'Quantidade de resgate menu': data.menuQuantity,
			'Valor do menu': formatUtil.formatCurrency(data.menuPrice),
			'Quantidade de resgate do vinho': data.wineQuantity,
			'Valor do vinho': formatUtil.formatCurrency(data.winePrice),
			'Valor total': formatUtil.formatCurrency(data.totalPrice),
			'Valor de Repasse': formatUtil.formatCurrency(data.totalTransferValue),
			'Grupo/Envio': data.group === '30' ? '30 dias' : data.group === '60' ? '60 dias' : 'Sem grupo',
		}
	}
}
