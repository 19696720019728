function formatDate(date: Date) {
	const [day, month, year] = date
		.toLocaleString('pt-br', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			timeZone: 'America/Sao_Paulo',
		})
		.split('/')

	return `${year}-${month}-${day}`
}

//2º tentativa se a primeira falhar
// const offset = yourDate.getTimezoneOffset()
// yourDate = new Date(yourDate.getTime() + offset * 60 * 1000)
// yourDate.toISOString().split('T')[0]

export function getTime(dateISOString: string) {
	const date = new Date(dateISOString)
	return date.toLocaleString('pt-br', { hour: '2-digit', minute: '2-digit', timeZone: 'America/Sao_Paulo' })
}

// date on format YYYY-MM-dd
export type TDate = `${number}${number}${number}-${number}${number}-${number}${number}`
function addDay(date: string, days: number) {
	const dateObj = new Date(date)
	dateObj.setDate(dateObj.getDate() + days)
	return dateObj.toISOString().split('T')[0]
}

function formatDateCheckin(date: Date) {
	return Intl.DateTimeFormat('fr-CA', { month: '2-digit', day: '2-digit', year: 'numeric' }).format(date)
}
function humanReadableDate(date: Date) {
	const dateFormatted = new Intl.DateTimeFormat('pt-BR', {
		weekday: 'long',
		day: 'numeric',
		month: 'long',
		year: 'numeric',
	}).format(date)
	const captalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
	return captalizeFirstLetter(dateFormatted)
}

function getPreviousMonthDates() {
	const today = new Date()
	const previousMonthLastDate = new Date(today.getFullYear(), today.getMonth(), 0) // Obtem a última data do mês anterior
	const previousMonthFirstDate = new Date(today.getFullYear(), today.getMonth() - 1, 1) // Obtem a primeira data do mês anterior
	const startDate = previousMonthFirstDate
	const endDate = previousMonthLastDate

	return { startDate, endDate }
}

function formatDataHour(dateISO: string): string {
	const date = new Date(dateISO)

	const dateFormat = new Intl.DateTimeFormat('pt-BR', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
	}).format(date)
	const hourFormat = new Intl.DateTimeFormat('pt-BR', { hour: '2-digit', minute: '2-digit' }).format(date)

	return `${dateFormat} às ${hourFormat}` //output: DD/MM/YYYY às HH:MM
}

export const DateUtils = {
	formatDate,
	formatDateCheckin,
	humanReadableDate,
	addDay,
	getPreviousMonthDates,
	formatDataHour,
}

export function calculateDateLimit(date: Date): Date {
	const newDate = new Date(date)
	newDate.setDate(newDate.getDate() + 7)
	return newDate
}

export function formatMonthYear(date: Date) {
	const months = [
		'Janeiro',
		'Fevereiro',
		'Março',
		'Abril',
		'Maio',
		'Junho',
		'Julho',
		'Agosto',
		'Setembro',
		'Outubro',
		'Novembro',
		'Dezembro',
	]
	const month = months[date.getMonth()]
	const year = date.getFullYear()
	return `${month}/${year}`
}

export function calculateEndDate(
	date: Date,
	slug: 'amenidades-restaurante' | 'celebrations-restaurante' | 'nota-fiscal-venue' | string
) {
	date.setUTCHours(10, 0, 0, 0)
	if (slug === 'amenidades-restaurante' || slug === 'nota-fiscal-venue') {
		const mes = date.getMonth() + 1
		const ano = date.getFullYear()
		return `20/${mes.toString().padStart(2, '0')}/${ano}`
	}
	if (slug === 'celebrations-restaurante') {
		const ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
		const mes = date.getMonth() + 1 // Adicionando 1, pois os meses são indexados de 0 a 11
		const ano = date.getFullYear()
		return `${ultimoDia}/${mes.toString().padStart(2, '0')}/${ano}`
	}
	return ''
}

// output: DD/MM/YYYY
export function formatDateToDDMMYYYY(date: Date): string {
	date.setUTCHours(10, 0, 0, 0)
	return new Intl.DateTimeFormat('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(
		date
	)
}
