<div class="page">
	<div class="page__wrapper wrapper">
		<app-back-button></app-back-button>
	</div>

	<div class="page__body">
		<div class="page__wrapper wrapper">
			<div class="edit">
				<!-- FORM -->
				<div class="edit__form">
					<!-- VENUE ID -->
					<label class="edit__label" for="venueId">
						<span>VenueId</span>
					</label>
					<div
						id="venueId"
						name="venueId"
						type="text"
						class="edit__input edit__input--disabled"
						placeholder="Ex.: Uma sobremesa"
					>
						{{ venueId }}
					</div>

					<div class="alert alert-info">
						<strong>Atenção:</strong> A mudança terá efeito em todos os programas de canais.
					</div>

					<form [formGroup]="form" class="radio-group">
						<div *ngFor="let option of options" class="radio-option" (click)="input.click()">
							<label for="{{ option.value }}" class="radio-label">
								<input
									type="radio"
									id="{{ option.value }}"
									formControlName="michelinGuide"
									[value]="option.value"
									#input
								/>
								{{ option.label }}
							</label>
						</div>
					</form>

					<button class="edit__button button" (click)="save()" [disabled]="loading">
						{{ loading ? 'Salvando...' : 'Salvar' }}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<ch-modal [id]="modal.error" top="15%" [preventOutsideClickClose]="true">
	<app-modal-simple [message]="errorMessage"></app-modal-simple>
</ch-modal>

<ch-modal [id]="modal.success" top="15%" [preventOutsideClickClose]="true">
	<app-modal-simple message="Opção Michelin salvo com sucesso!"></app-modal-simple>
</ch-modal>
