import { Component, Input } from '@angular/core'

@Component({
	selector: 'app-general-table',
	templateUrl: './general-table.component.html',
	styleUrls: ['./general-table.component.scss'],
})
export class GeneralTableComponent {
	@Input() info: any[] | null = null
	@Input() headersMap: { [key: string]: string } = {}
	@Input() headersDescription: { [key: string]: string } = {}

	get headers() {
		if (this.info !== null) {
			return this.info.length ? Object.keys(this.info[0]) : []
		}
		return []
	}

	getHeaderDescription(header: string): string {
		return this.headersDescription[header] || ''
	}
}
