<!-- MODAL -->
<ch-modal [id]="modal.search" top="150px" [preventOutsideClickClose]="true">
	<ng-container [formGroup]="searchForm">
		<app-modal-venue
			[hasVenues]="hasVenues"
			[venues]="venues"
			[lastVenues]="lastVenues$ | async"
			(onCloseModal)="closeModalSearch()"
			(onClickVenue)="secondStep($event)"
			(onEraseHistory)="eraseHistory()"
		></app-modal-venue>
	</ng-container>
</ch-modal>

<ch-modal [id]="modal.partner" top="150px" [preventOutsideClickClose]="true">
	<ng-container [formGroup]="partnerForm">
		<app-modal-partner (confirmClicked)="firstStep()"></app-modal-partner>
	</ng-container>
</ch-modal>

<ch-modal [id]="modal.createVenueConfig" top="150px" [preventOutsideClickClose]="true">
	<app-modal-simple
		message="Deseja criar uma nova configuração para essa venue?"
		(confirmClicked)="createVenueConfig()"
		(cancelClicked)="location.back()"
		template="choose"
	></app-modal-simple>
</ch-modal>
<ch-modal [id]="modal.success" top="150px">
	<app-modal-simple [id]="modal.success" [message]="successMessage" template="goBack"></app-modal-simple>
</ch-modal>

<ch-modal [id]="modal.error" top="150px" [preventOutsideClickClose]="true">
	<app-modal-simple [message]="errorMessage"></app-modal-simple>
</ch-modal>

<ch-modal [id]="modal.image" top="150px">
	<app-modal-simple
		message="Deseja atualizar as imagens desse restaurante?"
		(confirmClicked)="updateImage()"
		(cancelClicked)="closeModal('image')"
		template="choose"
	></app-modal-simple>
</ch-modal>

<div class="page">
	<app-header-master></app-header-master>

	<div class="page__body">
		<div class="page__wrapper wrapper">
			<app-back-button addClass="element" *ngIf="state.selectVenue === 'resolved'"></app-back-button>

			<app-loading001 *ngIf="state.selectVenue === 'pending'"></app-loading001>

			<div class="venues-content" *ngIf="selectedVenue">
				<h2 class="venues-content__title">{{ partnerTitle }}</h2>

				<!-- GESTÃO DE ESTABELECIMENTO -->
				<ng-container>
					<app-accordion
						slug="002"
						name="Gestão de estabelecimentos (Venues)"
						[tempAccordion]="tempVenue"
					>
					</app-accordion>
					<ng-template #tempVenue>
						<div class="venues-content__grid" style="position: relative">
							<div class="venues-content__column">
								<app-card-description
									[id]="selectedVenue._id"
									[name]="selectedVenue.name"
									[description]="transformAddress(selectedVenue.address)"
									[venueId]="selectedVenue._id"
								></app-card-description>

								<!-- (clickedIcon)="openModal('search')" -->
								<button
									style="position: absolute; top: 0; right: 0; width: 100px"
									type="button"
									class="submit__button button__light"
									(click)="openModal('image')"
								>
									Atualizar Imagens
								</button>
								<form [formGroup]="managementVenuesForm">
									<label class="switch">
										<input
											type="checkbox"
											class="switch__input"
											formControlName="enabled"
										/>
										<div class="switch__round"></div>
										<p class="switch__txt">{{ switchTxt }}</p>
									</label>
									<div style="height: 12px"></div>
								</form>

								<button
									type="submit"
									class="submit__button button"
									[class.disabled]="loadingSave"
									[disabled]="loadingSave"
									(click)="save()"
								>
									Salvar
								</button>
								<!-- <label class="switch">
									<input type="checkbox" class="switch__input" />
									<div class="switch__round"></div>
									<p class="switch__txt">Tag Restaurante Novo (ligado)</p>
								</label> -->
							</div>
						</div>
						<!-- <button type="submit" class="submit__button button">Salvar</button> -->
					</ng-template>
				</ng-container>

				<ng-container>
					<app-accordion
						slug="002"
						name="Gestão de Imagens e Tipos"
						[tempAccordion]="tempTypes"
					></app-accordion>
					<ng-template #tempTypes>
						<div class="venues-content__grid">
							<div style="display: flex; justify-content: space-between; width: 100%">
								<app-card-description
									name="Imagens"
									description="Editar Imagens"
									icon="tagmeicon-design"
									(click)="openEdit('edit-images')"
								></app-card-description>
								<app-card-description
									name="Tipos"
									description="Editar Tipos"
									icon="tagmeicon-mais-bolinha"
									(click)="openEdit('edit-types')"
								></app-card-description>
								<app-card-description
									name="Emails"
									description="Editar emails"
									icon="tagmeicon tagmeicon-email"
									(click)="openEdit('edit-emails')"
								></app-card-description>
								<app-card-description
									name="Michelin"
									description="Editar guia michelin"
									icon="tagmeicon tagmeicon-selo-menu-personnalite"
									(click)="openEdit('edit-michelin')"
								></app-card-description>
							</div>
						</div>
					</ng-template>
				</ng-container>

				<!-- GESTÃO DE BENEFITS -->

				<ng-container *ngIf="selectedVenue?.benefits?.length">
					<app-accordion slug="002" name="Gestão de benefícios" [tempAccordion]="tempBenefits">
					</app-accordion>

					<ng-template #tempBenefits>
						<div class="venues-content__grid">
							<div class="venues-content__column">
								<!-- GESTÃO DE BENEFÍCIOS -->
								<app-card-description
									*ngFor="let item of selectedVenue?.benefits"
									[id]="item._id"
									[img]="item.icon"
									[name]="item.name"
									[description]="item.description"
									(click)="openEditBenefitException(item._id)"
								>
								</app-card-description>
							</div>
						</div>
					</ng-template>
				</ng-container>
				<ng-container>
					<app-accordion
						slug="002"
						name="Gestão de Check-In"
						[tempAccordion]="tempCheckin"
					></app-accordion>
					<ng-template #tempCheckin>
						<div class="venues-content__grid">
							<app-card-description
								name="Checkin"
								description="Editar Check-In"
								icon="tagmeicon-info-mini"
								(click)="openEdit('edit-checkin')"
							></app-card-description>
						</div>
					</ng-template>
				</ng-container>
				<ng-container>
					<app-accordion
						slug="002"
						name="Gestão de Pagamento"
						[tempAccordion]="tempPayment"
					></app-accordion>
					<ng-template #tempPayment>
						<div class="venues-content__grid">
							<app-card-description
								name="Pagamento"
								description="Editar Pagamento"
								icon="tagmeicon-dinheiro"
								(click)="openEdit('edit-payments')"
							></app-card-description>
						</div>
					</ng-template>
				</ng-container>
			</div>
		</div>
	</div>
</div>
