import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import {
	filter,
	distinctUntilChanged,
	switchMap,
	Observable,
	of,
	take,
	Subject,
	takeUntil,
	Subscription,
} from 'rxjs'
import { ModalService } from 'src/app/components/modalChannels/modal.service'
import { RouterExtService } from 'src/app/services/router.service'
import { Venue } from '../venues.interface'
import { VenuesManagementCacheService } from './venue-management-cache.service'
import { VenuesApi } from '../../../services/venues.service'
import { LOCAL_KEYS, VenueLocalStorage, VenuesLocalStorage } from '../../../services/venue-ls.service'
import { HttpStatus } from 'src/app/services/get-async.util'
import { ActivatedRoute, Router } from '@angular/router'
import { VenuesManagementService } from '../venues-management.service'
import { PartnersId } from 'src/shared/util'
import { VenuesConfigApi } from 'src/app/services/venues-config/venues-config.service'
import { Location } from '@angular/common'
import { PartnersApi } from 'src/app/services/partner.service'

@Component({
	selector: 'app-venues',
	templateUrl: './venue-management.component.html',
	styleUrls: ['./venue-management.component.scss'],
})
export class VenuesManagementPage implements OnInit, OnDestroy, AfterViewInit {
	private destroy$ = new Subject<void>()
	private subscription = new Subscription()
	// SelectedVenue
	selectedVenue: Venue | null = null

	// Forms
	searchForm!: UntypedFormGroup
	managementVenuesForm!: UntypedFormGroup
	partnerForm!: UntypedFormGroup

	// Helpers
	state: Record<'selectVenue' | 'findOne', HttpStatus> = {
		selectVenue: 'idle',
		findOne: 'idle',
	}
	modal = {
		search: 'modal-search',
		partner: 'modal-partner',
		createVenueConfig: 'modal-create-venue-config',
		success: 'modal-success',
		error: 'modal-generic-error',
		image: 'modal-image',
	} as const
	storage: LOCAL_KEYS = 'default'
	errorMessage: string | null = null
	successMessage: string | null = null
	partnerTitle: string | null = null
	loadingSave = false
	switchTxt: string = 'Desligado'

	// Venues
	venues: Venue | null = null
	hasVenues: boolean = false

	// Observables
	selectedVenue$: Observable<any | null> = of(null)
	lastVenues$!: Observable<VenueLocalStorage[] | null>

	constructor(
		private venuesService: VenuesApi,
		private fb: UntypedFormBuilder,
		private routerExt: RouterExtService,
		private modalService: ModalService,
		private venuesCache: VenuesManagementCacheService,
		private venuesLocalStorage: VenuesLocalStorage,
		private route: ActivatedRoute,
		private router: Router,
		private venuesManagementService: VenuesManagementService,
		private venuesConfigApi: VenuesConfigApi,
		private partnerApi: PartnersApi,
		public location: Location
	) {}

	ngOnInit(): void {
		this.searchForm = this.fb.group({ name: ['', Validators.required] })
		this.managementVenuesForm = this.fb.group({
			enabled: [false],
			emailConfig: this.fb.group({
				enabled: [false],
				email: [null, Validators.email],
			}),
		})
		this.partnerForm = this.fb.group({ partnerId: [null, Validators.required] })
		this.suggestionsAfterTyping()

		this.changeSwitchText()
	}

	ngAfterViewInit(): void {
		if (!this.routerExt.previousUrl.includes('edit')) {
			this.openModal('partner')
			this.venuesManagementService.reset()
		}
		if (this.venuesManagementService.venueId) {
			// fix bug
			setTimeout(() => {
				this.setSelectedVenue({ _id: this.venuesManagementService.venueId } as any)
				this.getVenueConfig()
			}, 0)
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
		this.subscription.unsubscribe()
	}

	firstStep() {
		if (this.partnerForm.invalid) return
		this.closeModal('partner')
		this.choosePartnerId()
		this.openModal('search')
	}

	secondStep(venue: VenueLocalStorage) {
		this.closeModal('search')
		this.setSelectedVenue(venue)
		this.getVenueConfig()
	}

	save() {
		// this.loadingSave = true
		try {
			const primaryKey = this.venuesManagementService.primaryKey
			const { enabled, emailConfig } = this.managementVenuesForm.value
			const form = emailConfig.email ? { enabled, emailConfig } : { enabled }
			this.venuesConfigApi.updateVenueConfigRoot({ ...primaryKey, ...form }).subscribe({
				next: () => {
					this.successMessage = 'Restaurante atualizado com sucesso!'
					this.openModal('success')
				},
				error: error => {
					this.errorMessage = error?.error?.message
					this.openModal('error')
				},
				complete: () => {
					this.loadingSave = false
				},
			})
		} catch (error) {}
	}

	private choosePartnerId() {
		const { partnerId } = this.partnerForm.value
		this.venuesManagementService.partnerId = partnerId
		if (partnerId === PartnersId.BRADESCO) {
			this.storage = 'bradesco'
			this.partnerTitle = 'Programa Menu Bradesco'
		}
		if (partnerId === PartnersId.ESFERA) {
			this.storage = 'esfera'
			this.partnerTitle = 'Esfera Santander'
		}
		if (partnerId === PartnersId.LATAM) {
			this.storage = 'latam'
			this.partnerTitle = 'Latam'
		}
		if (partnerId === PartnersId.BB) {
			this.storage = 'bb'
			this.partnerTitle = 'BB Gastronomia'
		}
		this.lastVenues$ = this.venuesLocalStorage.getVenuesKey(this.storage)
	}

	private suggestionsAfterTyping() {
		const searchControl = this.searchForm.get('name')
		searchControl?.valueChanges
			.pipe(
				filter(name => name.length >= 3),
				distinctUntilChanged(),
				switchMap(name => this.venuesService.getVenues(name)),
				takeUntil(this.destroy$)
			)
			.subscribe(venues => {
				this.venues = venues
				this.hasVenues = Boolean(this.venues.length)
			})

		// Erase suggestions after delete content
		searchControl?.valueChanges
			.pipe(filter(name => name.length < 3))
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.venues = null
				this.hasVenues = false
			})
	}

	private changeSwitchText() {
		const enabledControl = this.managementVenuesForm.get('enabled')
		enabledControl?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
			this.switchTxt = value ? 'Ligado' : 'Desligado'
		})
	}

	closeModalSearch() {
		if (!this.selectedVenue) {
			alert('É necessário pesquisar uma venue.')
			return
		}
		this.closeModal('search')
	}

	openModal(key: keyof typeof this.modal) {
		this.modalService.open(this.modal[key])
	}

	closeModal(key: keyof typeof this.modal) {
		this.modalService.close(this.modal[key])
	}

	setSelectedVenue(venue: VenueLocalStorage) {
		this.state.selectVenue = 'pending'
		this.venuesManagementService.venueId = venue._id
		const { partnerId } = this.venuesManagementService.primaryKey
		this.venuesCache
			.getVenueById(venue, partnerId)
			.pipe(take(1))
			.subscribe({
				next: venue => {
					this.state.selectVenue = 'resolved'
					this.selectedVenue = venue
					this.venuesLocalStorage.addVenuesToLocalStorage(venue, this.storage)
					this.venues = []
				},
				error: () => {
					this.state.selectVenue = 'rejected'
				},
			})
	}

	getVenueConfig() {
		this.venuesManagementService.updateVenueConfig().subscribe({
			next: venueConfig => {
				this.switchTxt = venueConfig.enabled ? 'Ligado' : 'Desligado'
				this.managementVenuesForm.patchValue({
					enabled: Boolean(venueConfig.enabled),
				})
			},
			error: error => {
				if (error?.error?.message.includes('não encontrado')) {
					this.openModal('createVenueConfig')
					return
				}
				this.errorMessage = error?.error?.message
				this.openModal('error')
			},
		})
		this.venuesManagementService.getPartner().subscribe()
	}

	updateImage() {
		const primaryKey = this.venuesManagementService.primaryKey
		this.venuesConfigApi.getImagesFromBradesco(primaryKey).subscribe({
			next: () => {
				this.closeModal('image')
				this.successMessage = 'Imagens atualizadas com sucesso!'
				this.openModal('success')
			},
			error: error => {
				this.errorMessage = error?.error?.message
				this.openModal('error')
			},
		})
	}

	createVenueConfig() {
		const primaryKey = this.venuesManagementService.primaryKey
		this.venuesConfigApi.create(primaryKey).subscribe({
			next: () => {
				this.closeModal('createVenueConfig')
				this.successMessage = 'Configuração criada com sucesso!'
				this.openModal('success')
				this.getVenueConfig()
			},
			error: error => {
				this.errorMessage = error?.error?.message
				this.openModal('error')
			},
		})
	}

	openEditBenefitException(id: string) {
		this.venuesManagementService.benefitId = id
		this.router.navigate(['../edit-benefit'], { relativeTo: this.route })
	}

	openEdit(
		path:
			| 'edit-checkin'
			| 'edit-payments'
			| 'edit-types'
			| 'edit-images'
			| 'edit-emails'
			| 'edit-michelin'
	) {
		this.router.navigate([`../${path}`], { relativeTo: this.route })
	}

	eraseHistory() {
		this.venuesLocalStorage.removeVenuesLocalStorage(this.storage)
	}

	transformAddress(address: any | undefined) {
		if (!address) {
			return ''
		}
		return `${address?.address}, ${address?.neighborhood}, ${address?.city}, ${address?.state}`
	}
}
