import { Component, OnDestroy, OnInit } from '@angular/core'
import { VenuesManagementService } from '../venues-management.service'
import { VenuesConfigApi } from 'src/app/services/venues-config/venues-config.service'
import { ModalService } from 'src/app/components/modalChannels/modal.service'
import { finalize, Subject, takeUntil } from 'rxjs'
import { FormBuilder } from '@angular/forms'

@Component({
	templateUrl: 'edit-michelin.page.html',
	styleUrls: ['edit-michelin.page.scss'],
})
export class EditMichelinPage implements OnInit, OnDestroy {
	venueId: string | null = this.venuesManagementService.venueId
	errorMessage: string | null = null
	email: string[] = []
	newEmail: { [key: string]: string } = {}
	form = this.fb.group({ michelinGuide: [''] })
	options = [
		{ label: 'Michelin', value: 'michelin' },
		{ label: 'Michelin Star', value: 'michelin_star' },
		{ label: 'Michelin Bib Gourmand', value: 'michelin_bib_gourmand' },
	]
	loading = false

	modal = {
		error: 'modalErrorId',
		success: 'modalSuccessId',
	}

	private destroy$ = new Subject<void>()

	constructor(
		private venuesManagementService: VenuesManagementService,
		private fb: FormBuilder,
		private venuesConfigApi: VenuesConfigApi,
		private modalService: ModalService
	) {}

	ngOnInit(): void {
		this.venuesManagementService.ensurePrimaryKey()
		// update which radio button was selected
		this.venuesManagementService.venueConfig$.pipe(takeUntil(this.destroy$)).subscribe(config => {
			if (!config?.michelinGuide) return
			this.form.controls.michelinGuide.setValue(config.michelinGuide)
		})
	}

	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
	}

	save() {
		const michelinGuide = this.form.controls.michelinGuide.value
		if (this.venueId && michelinGuide) {
			this.loading = true
			this.venuesConfigApi
				.updateMichelinGuide(this.venueId, michelinGuide)
				.pipe(finalize(() => (this.loading = false)))
				.subscribe({
					next: () => {
						this.modalService.open(this.modal.success)
					},
					error: err => {
						this.errorMessage = err.error.message
						this.modalService.open(this.modal.error)
					},
				})
		}
	}
}
