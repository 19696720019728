<div class="page venue-home">
	<app-header-master *ngIf="isAdmin"></app-header-master>
	<app-header-venue
		*ngIf="(venue$ | async) as venue"
		[name]="venue.name"
		(onClick)="selectVenue()"
	></app-header-venue>
	<div class="page__body">
		<div class="wrapper venue-home__wrapper">
			<div class="venue-home__cards">
				<app-generic-card [link]="'/venue/' + venueId+ '/pagamentos/detalhes-dia'" icon="voucher-mini"
					>Programas Gastronômicos (Pagamentos)</app-generic-card
				>
				<app-generic-card [link]="'/checkin/' +venueId" icon="pin-24"
					>Passantes Check-In</app-generic-card
				>
				<app-generic-card
					*ngIf="has[partnersId.BRADESCO]"
					[link]="'/venue/'+venueId+'/relatorios/reservas-e-checkins-bradesco'"
					icon="garfo-faca-mini"
					>Relatórios Programa Menu</app-generic-card
				>
				<app-generic-card
					*ngIf="has[partnersId.ITAU]"
					[link]="'/venue/'+venueId+'/relatorios/reservas-e-checkins-mp'"
					icon="garfo-faca-mini"
					>Relatório Menu Personnalité</app-generic-card
				>
			</div>
		</div>
	</div>
</div>

<ch-modal [id]="modalSearchId" top="150px" [preventOutsideClickClose]="true">
	<ng-container [formGroup]="searchForm">
		<app-modal-venue
			[hasVenues]="hasVenues"
			[venues]="venues"
			[lastVenues]="lastVenues$ | async"
			(onCloseModal)="closeModal()"
			(onClickVenue)="clickVenues($event)"
			(onEraseHistory)="eraseHistory()"
		></app-modal-venue>
	</ng-container>
</ch-modal>

<ch-modal [id]="modalVenueAdminOptions" top="150px" [preventOutsideClickClose]="false">
	<section class="modal-venue-admin">
		<h1>Selecione um estabelecimento</h1>
		<br />
		<ng-container *ngIf="venuesAdminOptions$ | async as venuesAdminOptions">
			<button
				*ngFor="let venue of venuesAdminOptions"
				class="modal-venue-admin__name"
				(click)="selectVenueVenueAdmin(venue._id)"
			>
				{{venue.name}}
			</button>
		</ng-container>
	</section>
</ch-modal>
