import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Option, ReportsItauService } from '../report-menu-itau/reports.service'
import { FormBuilder, FormControl, Validators } from '@angular/forms'
import { DateUtils, formatDateToDDMMYYYY } from 'src/app/services/date.util'
import { FileSaverService } from 'ngx-filesaver'
import { PartnersId } from 'src/shared/util'
import { HttpStatus } from 'src/app/services/get-async.util'
import { calculateDateLimit, formatMonthYear, calculateEndDate } from 'src/app/services/date.util'
import { ModalService } from 'src/app/components/modalChannels/modal.service'
import {
	ReportGiftItau,
	ReportGiftItauConverted,
	ReportItauRedemptionsConverted,
	ReportItauRedemptionsResponse,
} from '../report-menu-itau/report.interface'
import { Venue } from '../../venue-management/venues.interface'
import { VenuesApi } from 'src/app/services/venues.service'
import { debounceTime, filter, distinctUntilChanged, switchMap, finalize } from 'rxjs'

@Component({
	selector: 'app-report-export',
	templateUrl: './report-export.component.html',
	styleUrls: ['./report-export.component.scss'],
})
export class ReportExportItauComponent implements OnInit {
	public idSector = 0
	public filterMonth = false
	public datePeriod = true
	public filterCardAndRest = false
	public searchTab = true
	public waitlist = []
	public options = this.reportsService.options
	public selectedOption: Option = this.options[0]
	public status: HttpStatus = 'idle'
	public filter = [
		{ title: '30 dias', value: '30' },
		{ title: '60 dias', value: '60' },
	]
	public tabRestAndCard = [
		{ title: 'Ações restaurante', id: 'restaurant' },
		{ title: 'Reserva cartão', id: 'card' },
	]
	public periods = [
		{ title: 'Período inicial', icon: 'tagmeicon tagmeicon-calendario-24', function: 'firstPeriod' },
		{ title: 'Período final', icon: 'tagmeicon tagmeicon-calendario-24', function: 'endPeriod' },
	]
	public report: ReportItauRedemptionsResponse[] | null = null
	public reportFiltered: ReportItauRedemptionsConverted[] | null = null
	public reportWithoutGroup: ReportItauRedemptionsConverted[] | null = null
	public monthYear = ''
	public dateLimit!: Date
	public endDate = ''
	public emailSendResult: any
	public isSendingEmail = false
	public reportGift: ReportGiftItau[] = []
	public reportGiftCoverted: ReportGiftItauConverted[] = []

	venues: Venue[] = []
	venueSelected: Venue | undefined
	searchControl = new FormControl('')
	hasVenues: boolean = false

	modal = {
		retry: 'modalRetryId',
		success: 'modalSuccessId',
	}
	form = this.fb.nonNullable.group({
		startDate: [
			DateUtils.getPreviousMonthDates().startDate.toISOString().substring(0, 10),
			[Validators.required],
		],
		endDate: [
			DateUtils.getPreviousMonthDates().endDate.toISOString().substring(0, 10),
			[Validators.required],
		],
		filterDate: [this.filter[0].value, [Validators.required]],
		venueName: [],
		lote: [],
	})

	constructor(
		private activatedRoute: ActivatedRoute,
		private reportsService: ReportsItauService,
		private fb: FormBuilder,
		private fileSaverService: FileSaverService,
		public modalService: ModalService,
		private venuesService: VenuesApi
	) {}

	ngOnInit(): void {
		const slug = this.activatedRoute.snapshot.paramMap.get('slug')
		this.selectedOption = this.options.find(option => option.slug === slug) ?? this.options[0]

		//search venue
		this.searchControl.valueChanges
			.pipe(
				debounceTime(500),
				filter(term => term!.length >= 3),
				distinctUntilChanged(),
				switchMap(term => this.venuesService.getVenues(term!))
			)
			.subscribe(venues => {
				this.venues = venues
				this.hasVenues = true
			})

		//clear venues
		this.searchControl.valueChanges.pipe(filter(term => term!.length < 3)).subscribe(() => {
			this.venues = []
			this.hasVenues = false
		})

		this.form.controls.filterDate.valueChanges.subscribe({
			next: value => {
				this.reportFiltered = this.filterGroup(value)
			},
		})
	}

	handleDownloadCsv() {
		this.status = 'pending'
		this.reportsService
			.chooseReportToCall(
				this.selectedOption.slug,
				{
					startDate: this.form.controls.startDate.value,
					endDate: DateUtils.addDay(this.form.controls.endDate.value, 1),
					partnerId: PartnersId.ITAU,
					filterDate: this.form.controls.filterDate.value,
				},
				'csv'
			)
			?.subscribe({
				next: (res: any) => {
					this.status = 'resolved'
					this.fileSaverService.save(res, `${this.selectedOption.slug}.csv`)
				},
				error: () => {
					this.status = 'rejected'
				},
			})
	}

	handlePreviewJson() {
		this.assignEndDate()
		this.status = 'pending'

		const reportData = {
			startDate: this.form.controls.startDate.value,
			endDate: DateUtils.addDay(this.form.controls.endDate.value, 1),
			partnerId: PartnersId.ITAU,
			filterDate: this.form.controls.filterDate.value,
		}

		this.reportsService.chooseReportToCall(this.selectedOption.slug, reportData, 'json')?.subscribe({
			next: res => {
				this.status = 'resolved'
				this.report = res
				if (this.selectedOption.slug === 'nota-fiscal-venue') {
					this.reportFiltered = this.filterGroup(this.form.controls.filterDate.value)
					this.reportWithoutGroup = res
						.filter(venue => !['30', '60'].includes(venue.group))
						.map(this.reportsService.reportsItauRedemptionAdapter)
				}
			},
			error: () => {
				this.status = 'rejected'
			},
		})
	}

	private filterGroup(group: string) {
		return (this.report?.filter(item => item.group === group) ?? []).map(
			this.reportsService.reportsItauRedemptionAdapter
		)
	}

	//for gift
	handleReportGift() {
		this.status = 'pending'

		const data = {
			startDate: this.form.controls.startDate.value,
			endDate: this.form.controls.endDate.value,
			partnerId: PartnersId.ITAU,
			venue: this.venueSelected._id,
			lote: this.form.controls.lote.value,
		}

		this.reportsService.chooseReportToCall(this.selectedOption.slug, data, 'json')?.subscribe({
			next: res => {
				this.status = 'resolved'
				this.reportGift = res
				this.reportGiftCoverted = this.reportGift.map(this.reportsService.reportsItauGiftAdapter)
			},
			error: () => {
				this.status = 'rejected'
			},
		})
	}

	private assignEndDate() {
		const endDate = new Date(this.form.controls.endDate.value)
		this.dateLimit = calculateDateLimit(new Date())
		this.monthYear = formatMonthYear(endDate)
		this.endDate = calculateEndDate(new Date(), this.selectedOption.slug)
	}

	public searchLocalTab() {
		this.searchTab = false
	}

	openModalEmail() {
		this.modalService.open(this.modal.success)
	}

	openRetryModal() {
		this.modalService.open(this.modal.retry)
	}

	closeModal() {
		this.modalService.close(this.modal.success)
		this.modalService.close(this.modal.retry)
	}

	sendFinancialEmails() {
		this.isSendingEmail = true
		const emailData = {
			monthYear: this.monthYear,
			previewDateLimit: this.endDate,
			notaFiscalDateLimit: formatDateToDDMMYYYY(this.dateLimit),
			venuesToSend: (this.reportFiltered as ReportItauRedemptionsConverted[]).map(venue => {
				return {
					venueId: venue['ID da Venue'],
					venueName: venue['Restaurante'],
					qtdWine: venue['Quantidade de resgate do vinho'] || 0,
					qtdMenu: venue['Quantidade de resgate menu'] || 0,
					totalValue: venue['Valor de Repasse'],
				}
			}),
		}
		this.reportsService
			.sendFinancialEmail(emailData)
			.pipe(finalize(() => (this.isSendingEmail = false)))
			.subscribe(response => {
				this.emailSendResult = response
				this.closeModal()
			})
	}

	public shouldShowRetry(): boolean {
		return this.emailSendResult?.failures?.some(failure => failure.retry)
	}

	/*
	retryFailedEmails() {
		this.isSendingEmail = true
		const failuresToRetry = this.emailSendResult.failures.filter(failure => failure.retry)

		if (this.selectedOption.slug === 'reservas-itau-resgate') {
			const venuesToSend = failuresToRetry.map(failure => {
				return {
					venueId: failure.venueId,
					venueName: failure.venueName,
					totalValue: failure.totalTransferValue
						? formatUtil.formatCurrency(failure.totalTransferValue)
						: 'R$ 0,00',
				}
			})

			if (venuesToSend.length > 0) {
				const emailData = {
					monthYear: this.monthYear,
					dateLimit: formatDateToDDMMYYYY(this.dateLimit),
					endDate: this.endDate,
					venuesToSend: venuesToSend,
				}

				this.reportsService.sendFinancialEmail(emailData).subscribe(response => {
					this.emailSendResult = response
					this.closeModal()
				})
			}
		}
	}*/

	handleDownloadGiftCsv() {
		this.status = 'pending'
		const data = {
			startDate: this.form.controls.startDate.value,
			endDate: DateUtils.addDay(this.form.controls.endDate.value, 1),
			partnerId: PartnersId.ITAU,
			venue: this.form.controls.venueName.value,
			lote: this.form.controls.lote.value,
		}

		this.reportsService.chooseReportToCall(this.selectedOption.slug, data, 'csv')?.subscribe({
			next: res => {
				this.status = 'resolved'
				this.fileSaverService.save(res, `${this.selectedOption.slug}.csv`)
			},
			error: () => {
				this.status = 'rejected'
			},
		})
	}

	selectVenue(venue: Venue) {
		this.searchControl.setValue(venue.name, { emitEvent: false })
		this.venueSelected = venue
		this.hasVenues = false
	}
}
