import { Injectable } from '@angular/core'
import { environment as env } from 'src/environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { BenefitDto } from '../page/benefit/general-benefit/general-benefit.dto'

@Injectable({ providedIn: 'root' })
export class BenefitsApi {
	private root = 'benefits'
	private baseURL = `${env.channelsAPI.baseURL}${this.root}`
	constructor(private readonly http: HttpClient) {}

	findAll(partnerId: string) {
		const headers = new HttpHeaders().set('partnerId', partnerId)
		return this.http.get<BenefitDto[]>(`${this.baseURL}`, { headers })
	}
}
