import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ModalService } from '../../modalChannels/modal.service'
import { Item } from '../../select-box/select-box.component'

export type IDays = {
	firstDay: Date
	lastDay: Date
}

export type IPaymentFilter = {
	partnerId: string
	name: string
}

@Component({
	selector: 'app-payment-filter-details',
	templateUrl: './payment-filter-details.component.html',
	styleUrls: ['./payment-filter-details.component.scss'],
})
export class PaymentFilterDetails implements OnInit {
	calendarId = 'calendar_payment_id' as const
	firstDay = new Date()
	lastDay = new Date()
	selectedChannel = ''
	currentName = ''

	//PARA CAPTAR AS ORDERS SELECIONADAS
	@Input() isAnyCardSelected = false
	//PARA CAPTAR O TOTAL DAS ORDERS SELECIONADAS
	@Input() totalSelectedValue = 0

	@Input() channels: Item[] | null = null

	@Output() daysChanged = new EventEmitter<IDays>()
	@Output() filterChanged = new EventEmitter<IPaymentFilter>()

	@Output() onPrintClick = new EventEmitter<void>()
	@Output() onReportClick = new EventEmitter<void>()
	@Output() onDetailsClick = new EventEmitter<void>()

	constructor(private modalService: ModalService) {}

	ngOnInit(): void {
		this.daysChanged.emit({ firstDay: this.firstDay, lastDay: this.lastDay })
	}

	setChannel($event: Item) {
		this.selectedChannel = $event.value
		this.filterChanged.emit({ partnerId: this.selectedChannel, name: this.currentName })
	}

	setName(name: string) {
		this.currentName = name
		this.filterChanged.emit({ partnerId: this.selectedChannel, name: this.currentName })
	}

	selectDay(day: Date, type: 'first' | 'last') {
		if (type === 'first') {
			this.firstDay = day
		} else if (type === 'last') {
			this.lastDay = day
			this.daysChanged.emit({ firstDay: this.firstDay, lastDay: this.lastDay })
		}
	}

	openCalendar() {
		this.modalService.open(this.calendarId)
	}

	handlePrintClick() {
		this.onPrintClick.emit()
	}

	handleReportClick() {
		this.onReportClick.emit()
	}

	openDetails() {
		this.onDetailsClick.emit()
	}
}
