import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, ReplaySubject } from 'rxjs'
import { environment as env } from 'src/environments/environment'
import { VenuesCacheService } from '../payment/venue-cache.service'

@Injectable({ providedIn: 'root' })
export class CheckInService {
	private dateSub = new BehaviorSubject({ firstDate: new Date(), lastDate: new Date() })
	public date$ = this.dateSub.asObservable()

	private updateOrdersSub = new ReplaySubject<void>()
	public updateOrders$ = this.updateOrdersSub.asObservable()

	private updateGiftSub = new ReplaySubject<void>()
	public updateGift$ = this.updateGiftSub.asObservable()

	constructor(private http: HttpClient, private venuesCacheService: VenuesCacheService) {}

	setDate(firstDate: Date, lastDate: Date) {
		this.dateSub.next({ firstDate, lastDate })
	}

	updateOrder() {
		this.updateOrdersSub.next()
	}

	updateGift() {
		this.updateGiftSub.next()
	}

	getOrders(venueId: string, firstDate: string, lastDate: string, intent: string) {
		return this.http.get<any>(
			`${env.channelsAPI.baseURL}orders/admin/byinterval/${venueId}/${firstDate}/${lastDate}/${intent}`
		)
	}

	getGifts(venueId: string, firstDate: string, lastDate: string) {
		// TODO: I don't know why some clients in Brazil don't get timezone correclty
		// const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
		return this.http.get<any>(
			`${env.giftURL}/voucher/venue/${venueId}?startDate=${firstDate}&endDate=${lastDate}&timeZone=America/Sao_Paulo`
		)
	}

	validate(orderId: string) {
		return this.http.get<any>(`${env.channelsAPI.baseURL}orders/validate/${orderId}`)
	}

	reject(orderId: string) {
		return this.http.delete<any>(`${env.channelsAPI.baseURL}orders/admin/${orderId}`)
	}

	getVenueById(venueId: string) {
		return this.venuesCacheService.getVenueById(venueId)
	}
}
