import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { BenefitComponent } from './page/benefit/benefit.component'
import { GeneralBenefitComponent } from './page/benefit/general-benefit/general-benefit.component'
import { CategoryComponent } from './page/category/category.component'
import { CreateCategoryComponent } from './page/category/create-category/create-category.component'
import { CheckinPage } from './page/checkin/checkin.page'
import { ConciergeAdminComponent } from './page/concierge-admin/concierge-admin.component'
import { CreateConciergeAdminComponent } from './page/concierge-admin/create-concierge-admin/create-concierge-admin.component'
import { ConciergeComponent } from './page/concierge/concierge.component'
import { PaymentDetailsPage } from './page/payment/payment-details/payment-details.page'
import { PaymentInfoPage } from './page/payment/payment-info/payment-info.page'
import { VenueHomePage } from './page/payment/venue-home/venue-home.page'
import { ProjectsComponent } from './page/projects/projects.component'
import { ReportExportBradescoComponent } from './page/report/report-export-bradesco/report-export.component'
import { ReportBradescoComponent } from './page/report/report-menu-bradesco/report.component'
import { ReservationAdminComponent } from './page/reservation-admin/reservation-admin.component'
import { TagmeReservationPage } from './page/tagme/reservation/reservation.page'
import { TagmeSingleVoucherPage } from './page/tagme/single-voucher/single-voucher.page'
import { AuthGuardAdminChild } from './page/user/guards/auth-admin-child.guard'
import { AuthGuardVenue } from './page/user/guards/auth-venue.guard'
import { LoginComponent } from './page/user/login/login.component'
import { WaitlistAdminComponent } from './page/waitlist-admin/waitlist-admin.component'
import { VenuesManagementPage } from './page/venue-management/venue-management/venue-management.component'
import { WaitlistComponent } from './page/waitlist/waitlist.component'
import { EditCheckinPage } from './page/venue-management/edit-checkin/edit-checkin.page'
import { TagmeSyncVenuePage } from './page/tagme/sync-venue/sync-venue.page'
import { EditPaymentsPage } from './page/venue-management/edit-payment/edit-payment.page'
import { EditTypesPage } from './page/venue-management/edit-types/edit-types.page'
import { EditEmailsPage } from './page/venue-management/edit-email/edit-email.page'
import { SandBoxPage } from './page/sandbox/sandbox.page'
import { ItauVouchersPage } from './page/itau/vouchers/vouchers.page'
import { ItauSingleVoucherPage } from './page/itau/single-voucher/single-voucher.page'
import { EditImagesPage } from './page/venue-management/edit-images/edit-images.page'
import { OrderComponent } from './page/order/order.component'
import { VenueReportsOrdersBradescoPage } from './page/report/report-orders-bradesco/report-orders.page'
import { VenueReportsOrdersMpPage } from './page/report/report-orders-mp/report-orders.page'
import { ReportItauComponent } from './page/report/report-menu-itau/report.component'
import { ReportExportItauComponent } from './page/report/report-export-itau/report-export.component'
import { VouchersBBIPage } from './page/bradesco/vouchers-bbi/vouchers-bbi.page'
import { EditMichelinPage } from './page/venue-management/edit-michelin/edit-michelin.page'

const routes: Routes = [
	{ path: 'login', component: LoginComponent },
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: 'validacao/order', component: OrderComponent },
	{
		path: '',
		canActivateChild: [AuthGuardAdminChild],
		children: [
			{ path: 'home', component: ProjectsComponent },
			{ path: 'bradesco/category', component: CategoryComponent },
			{ path: 'bradesco/concierge', component: ConciergeComponent },
			{ path: 'bradesco/concierge-admin', component: ConciergeAdminComponent },
			{ path: 'bradesco/create-category', component: CreateCategoryComponent },
			{ path: 'bradesco/create-category/:id', component: CreateCategoryComponent },
			{ path: 'bradesco/create-concierge-admin', component: CreateConciergeAdminComponent },
			{ path: 'bradesco/create-concierge-admin/:id', component: CreateConciergeAdminComponent },
			{ path: 'bradesco/conciergeAdm', component: ConciergeAdminComponent },
			{ path: 'bradesco/report', component: ReportBradescoComponent },
			{ path: 'bradesco/vouchers-bbi', component: VouchersBBIPage },
			{ path: 'itau/report', component: ReportItauComponent },
			{ path: 'bradesco/report-export', component: ReportExportBradescoComponent },
			{ path: 'bradesco/report-export/:slug', component: ReportExportBradescoComponent },
			{ path: 'itau/report-export/:slug', component: ReportExportItauComponent },
			{ path: 'tagme/reservation', component: TagmeReservationPage },
			{ path: 'tagme/single-voucher', component: TagmeSingleVoucherPage },
			{ path: 'tagme/venue/:venueId', component: TagmeSyncVenuePage },
			{ path: 'tagme/venue-management', component: VenuesManagementPage },
			{ path: 'tagme/edit-checkin', component: EditCheckinPage },
			{ path: 'tagme/edit-payments', component: EditPaymentsPage },
			{ path: 'tagme/edit-images', component: EditImagesPage },
			{ path: 'tagme/benefit', component: BenefitComponent },
			{ path: 'tagme/create-benefit', component: GeneralBenefitComponent },
			{ path: 'tagme/edit-benefit', component: GeneralBenefitComponent },
			{ path: 'tagme/edit-types', component: EditTypesPage },
			{ path: 'tagme/edit-emails', component: EditEmailsPage },
			{ path: 'tagme/edit-michelin', component: EditMichelinPage },
			{ path: 'reservation-admin', component: ReservationAdminComponent },
			{ path: 'waitlist', component: WaitlistComponent },
			{ path: 'waitlist-admin', component: WaitlistAdminComponent },
			{ path: 'itau/vouchers', component: ItauVouchersPage },
			{ path: 'itau/single-voucher', component: ItauSingleVoucherPage },
			{ path: 'sandbox', component: SandBoxPage },
		],
	},
	{ path: 'checkin/history/:venueId', redirectTo: 'checkin/:venueId' },
	{
		path: 'checkin/:venueId',
		component: CheckinPage,
		data: {
			breadcrumbs: [
				{ url: '/venue/:venueId', title: 'Home' },
				{ url: '/checkin/:venueId', title: 'Check-In' },
			],
		},
		canActivate: [AuthGuardVenue],
	},
	{
		path: 'venue',
		component: VenueHomePage,
		canActivate: [AuthGuardVenue],
	},
	{
		path: 'venue/:venueId',
		component: VenueHomePage,
		canActivate: [AuthGuardVenue],
	},
	{
		path: 'venue/:venueId/pagamentos',
		component: PaymentInfoPage,
		data: {
			breadcrumbs: [
				{ url: '/venue/:venueId', title: 'Home' },
				{ url: '/venue/:venueId/pagamentos', title: 'Pagamentos Gastronômicos' },
			],
		},
		canActivate: [AuthGuardVenue],
	},
	{
		path: 'venue/:venueId/pagamentos/detalhes-dia',
		component: PaymentDetailsPage,
		data: {
			breadcrumbs: [
				{ url: '/venue/:venueId', title: 'Home' },
				// { url: '/venue/:venueId/pagamentos', title: 'Pagamentos Gastronômicos' },
				{ url: '/venue/:venueId/pagamentos/detalhes-dia', title: 'Detalhes' },
			],
		},
		canActivate: [AuthGuardVenue],
	},
	{
		path: 'venue/:venueId/relatorios/reservas-e-checkins-bradesco',
		component: VenueReportsOrdersBradescoPage,
		data: {
			breadcrumbs: [
				{ url: '/venue/:venueId', title: 'Home' },
				{
					url: '/venue/:venueId/relatorios/reservas-e-checkins-bradesco',
					title: 'Reservas e Check-ins',
				},
			],
		},
		canActivate: [AuthGuardVenue],
	},
	{
		path: 'venue/:venueId/relatorios/reservas-e-checkins-mp',
		component: VenueReportsOrdersMpPage,
		data: {
			breadcrumbs: [
				{ url: '/venue/:venueId', title: 'Home' },
				{
					url: '/venue/:venueId/relatorios/reservas-e-checkins-mp',
					title: 'Reservas e Check-ins',
				},
			],
		},
		canActivate: [AuthGuardVenue],
	},
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
